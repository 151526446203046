import { system } from '@theme-ui/presets'

const linkUnderline = '#d6bead'
export default {
  ...system,
  colors: {
    ...system.colors,
    text: '#37352f',
    link: '#4e4b3b',
    linkUnderline,
    accent: '#a9be5f',
    background: '#fff',
  },
  fontSizes: [12, 14, 16, 20, 24, 30, 48, 64, 72],
  fonts: {
    ...system.fonts,
    heading: 'Menlo',
    monospace: `Menlo, Monaco, "Lucida Console", "Liberation Mono", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Courier New", monospace, serif;`,
  },
  fontWeights: {
    ...system.fontWeights,
    heading: 700,
    body: 400,
  },
  textStyles: {
    display: {
      fontFamily: 'monospace',
      fontWeight: 'body',
      lineHeight: 'body',
      letterSpacing: 'body',
      fontSize: 1,
    },
    post: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      fontSize: 2,
    },
    heading: {
      mt: '1.5rem',
      mb: '0.5rem',
      fontWeight: 600,
      lineHeight: 1,
    },
  },
  styles: {
    ...system.styles,
    h1: {
      variant: 'textStyles.heading',
      fontSize: [4, 5],
    },
    h2: {
      variant: 'textStyles.heading',
      fontSize: [3, 4],
    },
    h3: {
      variant: 'textStyles.heading',
      fontSize: [2, 3],
    },
    h4: {
      variant: 'textStyles.heading',
      fontSize: 2,
    },
    h5: {
      variant: 'textStyles.heading',
      fontSize: 1,
    },
    p: {
      m: 0,
      mb: '1rem',
    },
    root: {
      fontFamily: 'monospace',
      fontWeight: 'body',
      lineHeight: 'body',
      fontSize: 1,
    },
  },
  links: {
    primary: {
      color: 'link',
      textDecoration: 'none',
      borderBottom: `3px solid ${linkUnderline}`,
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'none',
        boxShadow: `inset 0 -4px 0 ${linkUnderline}`,
        color: 'inherit',
      },
    },
  },
  sizes: {
    header: 50,
    border: 10,
  },
}
